<template>
	<div>
		<div v-if="viewno == 1">
			<div class="col-md-12 font">
				<div class="cardbox">
					<div class="col-md-12">
						<div class="row" style="margin-top: 20px">
							<div class="col-md-6">
								<h3 class="page-header">
									<i class="fa fa fa-users animated bounceInDown show-info"></i>
									Category
								</h3>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-6"></div>
									<div class="col-md-6" style="padding-top: 18px">
										<button @click="refresh()" class="btn btn-primary">
											<i class="fa fa-refresh"></i> Refresh
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="memberdivtable_1">
						<div class="card-body" style="border: 1px solid #e9dbdb; padding: 0">
							<div class="form-group mb-2">
								<div class="co-md-12">
									<div class="row" style="padding-left: 40px; margin-top: 8px">
										<div class="col-md-4 row">
											<p><b>Search Category Name</b></p>
											<input type="Search" v-model="searchname"
												class="form-cascade-control input-box-short" />
										</div>
										<div class="col-md-3">
											<button type="button" @click="searchName()" style="margin-top: 38px"
												class="btn btn-success btn-sm btnsearch">
												Search
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<nav aria-label="Page navigation example" style="float: right; margin-right: 28px">
					<paginate style="margin: 0px !important" :page-count="noofpages" :click-handler="clickCallback"
						:prev-text="'&laquo; Prev &nbsp;&nbsp;'" :next-text="'Next &raquo;'"
						:container-class="'pagination'">
					</paginate>
				</nav>

				<div class="cardbox1">
					<div class="col-md-12">
						<table class="table table-font">
							<thead>
								<tr>
									<th>S.No</th>
									<th>Name</th>
									<th style="width: 10%">Image</th>
									<th style="width: 8%">Action</th>
								</tr>
							</thead>
							<category-row v-for="(item, index) in categories" v-bind:key="item.id" :item="item"
								:index="index"></category-row>
						</table>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import Paginate from "vuejs-paginate";
import CategoryRow from "./CategoryRow.vue";

import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";
export default {
	mixins: [Constants],
	components: {
		Paginate,
		CategoryRow,
	},
	data() {
		return {
			categoryArray: [],
			status: { 0: "Disabled", 1: "Active" },
			isedit: false,
			category: null,
			size: 250,
			attributes: [],
			attribcategorymap: [],

      searchname: "",
      searchurl: "",
      searchrole: 0,
      searchstatus: 1,
      noofpages: 0,
    };
  },
  mounted() {
    this.$store.dispatch("fetchcategories");
    this.$store.dispatch("fetchattributes");
  },
  computed: {
    categorieslist() {
      return this.categories.slice(
        (this.page - 1) * this.noofrecords,
        (this.page - 1) * this.noofrecords + this.noofrecords
      );
    },
    ...mapGetters(["loggedinuser", "viewno", "categories"]),
  },
  methods: {
    deleteimage(imageitem, item) {
      let param = { imageid: imageitem.id };
      this.$http
        .post("image/remove", param)
        .then((response) =>
          this.processImageDeleteResponse(item, imageitem, response)
        )
        .catch((error) => console.log(error));
    },
    processImageDeleteResponse(item, imageitem) {
      let index = item.images.findIndex((block) => block.id == imageitem.id);
      if (index > -1) {
        item.images.splice(index, 1);
      }
    },
    clickCallback(pageNum) {
      this.page = pageNum;
      this.searchName();
    },
    searchName() {
      let param = {
        searchname: this.searchname,
        searchstatus: this.searchstatus,
        searchurl: this.searchurl,
        searchrole: this.searchrole,
        pageno: this.page,
        noofrecords: this.noofrecords,
      };

      this.$http
        .post("category/search", param)
        .then((response) => this.processCategoryResponse(response.data))
        .catch((err) => {
          console.log(err)
        });
    },
    processAttributeallListResponse(data) {
      this.noofpages = data;
      this.noofpages = Math.ceil(this.noofpages / this.noofrecords);
    },
    processloggedinuser(data) {
      this.$store.commit("assignloggedinuser", data);
    },
    getimageurl(imageitem, item) {
      return (
        window.axios.defaults.baseURL +
        "/categories/" +
        imageitem.id +
        "/" +
        this.size +
        "x" +
        this.size +
        "/" +
        item.url +
        "-" +
        imageitem.id +
        ".jpg"
      );
    },
    processCategoryResponse(data) {
      this.$store.commit("assigncategories", data);

			this.page = 1;
		},
		add() {
			this.$store.commit("assignviewno", 2);
			this.$store.commit("assignedititem", null);
		},

    saveimage(imageid) {
      //post the category id and the image id
      let param = { categoryid: this.category.id, imageid: imageid };
      if (this.category != null) {
        this.$http
          .post("api/category/processimage", param)
          .then((response) => {
            this.refreshimages(response.data);
          })
          .catch((err) => {
            console.log("Failed to get an S3 signed upload URL", err);
          });
      }
    },
    refreshimages(data) {
      let self = this;
      self.category.images = data;
      let index = this.categories.findIndex(
        (block) => block.id == self.category.id
      );
      if (index > -1) {
        //console.log("Setting");
        self.$set(self.categories, index, self.category);
      }
    },
    
  },
};
</script>
<style scoped>
.table td,
.table th {
	border: 1px solid #dee2e6;
}
</style>