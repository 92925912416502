<template>
  <tr v-if="item != null">
    <td>{{ index + 1 }}</td>
    <td>
      {{ item.name }}
    </td>
    <td><button type="button" class="badge btn-success btn-xs" @click="edit(item)"><i class="fa fa-edit"></i></button></td>
    <td v-if="false" v-text="item.url"></td>
    <td v-if="item.parentcategory != null">{{ item.parentcategory.name }}</td>
  </tr>
</template>
<script>
import Constants from "../../components/utilities/Constants.vue";
import { mapGetters } from "vuex";
export default {
  mixins: [Constants],
  props: ["item", "index"],
  data() {
    return {
      selectedids: [],
    };
  },
  computed: {
    ...mapGetters(["attributes"]),
    page() {
      return this.$parent.page;
    },
    noofpages() {
      return this.$parent.noofpages;
    },
    noofrecords() {
      return this.$parent.noofrecords;
    },
  },
  mounted() {
    if(this.item !=null){
      if(this.item.filters!=null)
        this.selectedids = this.item.filters.map((a) => a.attribute_id);
    }
  },
  methods: {
    updateselectedattributes() {
      let param = {
        category_id: this.item.id,
        attributeids: this.selectedids.join(),
      };
      this.$http
        .post("api/category/updateattributes", param)
        .then((response) => this.processCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    updatecheckboxincolumn(event, columnname) {
      let param = {
        id: this.item.id,
        columnname: columnname,
        value: event.target.checked ? 1 : 0,
      };
      this.$http
        .post("api/category/updatecolumn", param)
        .then((response) => this.processCategoryResponse(response.data))
        .catch((error) => console.log(error));
    },
    processCategoryResponse(data) {
      this.$store.commit("addupdatecategory", data);
    },
    edit(item){
      this.$notify({
						text:'Edit Category',
						type:'success',
						duration:'80000',
						speed:'800',
						closeOnClick:false
					})
      this.$store.commit("assignedititem",item)
    }
  },
};
</script>